import React, { useEffect, useState } from "react";
import "../../../assets/scss/views/SacPersonal.scss";

import { Alert } from "reactstrap";

import Loader from "components/Bridgestone/Loader.jsx";
import { Redirect } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Rating,
  Typography,
} from "@mui/material";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/TextField/TextField";
import FormHelper from "../../../helpers/FormHelper";
import { TaskAlt } from "@mui/icons-material";
import ClientsService from "../../../services/ClientsService";
import SatisfactionService from "../../../services/SatisfactionService";
import DialogSacPersonalConfirmSubmit from "../../../components/Dialog/DialogSacPersonalConfirmSubmit";

const currentFullYear = new Date().getFullYear();

const comoNosConheceyArray = [
  "Indicação de alguém",
  "Passando em frente",
  "Pesquisa no Google",
  "Redes sociais",
  "Site da Bridgestone",
  "Outro",
];

const RealizedPersonal = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [validCategory, setValidCategory] = useState(true);

  const [storeCode, setStoreCode] = useState("");
  const [category, setCategory] = useState("");
  const [confirmationDialogOpened, setConfirmationDialogOpened] =
    useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [surveyCompletedMessage, setSurveyCompletedMessage] = useState(
    "Obrigado por responder!"
  );

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [comoNosConheceu, setComoNosConheceu] = useState(
    comoNosConheceyArray[0]
  );
  const [comoNosConheceuEspecifique, setComoNosConheceuEspecifique] =
    useState("");
  const [atendimentoPresencial, setAtendimentoPresencial] = useState("Y");
  const [nomeFuncionario, setNomeFuncionario] = useState("");
  const [pesquisaServico, setPesquisaServico] = useState(0);
  const [pesquisaInformacao, setPesquisaInformacao] = useState(0);
  const [pesquisaPagamento, setPesquisaPagamento] = useState(0);
  const [pesquisaGeral, setPesquisaGeral] = useState(0);
  const [notaNps, setNotaNps] = useState(undefined);
  const [comentario, setComentario] = useState("");
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const params = props.location.pathname.split("/");
    const storeCodeAux = params[2];
    const categoryAux = params[3].toUpperCase();

    setStoreCode(storeCodeAux);
    setCategory(categoryAux);

    setLoading(true);

    ClientsService.FindByCode(storeCodeAux)
      .then((resp) => {
        const validCategory =
          resp.data &&
          resp.data.categories.filter(
            (cat) => cat.descr_pt.toUpperCase() == categoryAux
          ).length > 0;
        setValidCategory(validCategory);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleValidateEmail = (email, setter) => {
    if (email.length > 0) {
      const validEmail = FormHelper.ValidateEmail(email);

      if (!validEmail) {
        setter("");
        handleError("E-mail inválido");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (notaNps === undefined) {
      handleError(
        "Selecione uma nota de recomendação na questão 5 para continuar"
      );
      return;
    }

    let formDataAux = FormHelper.GenerateFormData(e, null);
    formDataAux = FormHelper.IncludeKeyValue(formDataAux, "category", category);
    formDataAux = FormHelper.IncludeKeyValue(
      formDataAux,
      "storeCode",
      storeCode
    );

    formDataAux = FormHelper.IncludeKeyValue(
      formDataAux,
      "general",
      pesquisaGeral
    );
    formDataAux = FormHelper.IncludeKeyValue(
      formDataAux,
      "service",
      pesquisaServico
    );
    formDataAux = FormHelper.IncludeKeyValue(
      formDataAux,
      "information",
      pesquisaInformacao
    );
    formDataAux = FormHelper.IncludeKeyValue(
      formDataAux,
      "payment",
      pesquisaPagamento
    );
    formDataAux = FormHelper.IncludeKeyValue(formDataAux, "nps", notaNps);

    setFormData(formDataAux);
    setConfirmationDialogOpened(true);
  };

  const handleSubmitConfirmation = () => {
    setLoading(true);

    SatisfactionService.ResponseSurvey(formData)
      .then((resp) => {
        setSurveyCompleted(true);
        setConfirmationDialogOpened(false);

        if (resp.data.hasOwnProperty("message")) {
          setSurveyCompletedMessage(resp.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.errors && err.response.data.errors.length > 0) {
          handleError(err.response.data.errors[0].message);
        } else {
          handleError(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (message) => {
    setError(message);

    setTimeout(() => {
      setError(undefined);
    }, 5000);
  };

  if (!validCategory) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100vh">
        <Typography component="h2" className="fontweight-600">
          404 - Pesquisa de satisfação não encontrada.
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      {loading && <Loader />}
      {error && (
        <Alert color="danger" className="alert-error">
          {error}
        </Alert>
      )}

      <Grid item xs={12} className="container-sacpersonal">
        <Grid item xs={12} className="container-header" />

        <Grid
          item
          component="form"
          onSubmit={handleSubmit}
          xs={12}
          className="container-content"
        >
          <Grid item component={Paper} md={9} xs={12} className="paper-form">
            {!surveyCompleted && (
              <>
                <Grid container className="container-form-header">
                  <Grid item xs={12} className="header-info">
                    <Typography
                      component="span"
                      variant="h6"
                      className="text-title-fixed fontweight-600"
                    >
                      Pesquisa de satisfação
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className="container-form-content">
                  <Grid item xs={12} className="container-form-fields">
                    <Grid container>
                      <Grid item md={6} xs={12} className="paddingright-6px">
                        <TextField
                          label="Seu nome"
                          name="name"
                          value={nome}
                          required={false}
                          onChange={(e) => setNome(e.target.value)}
                        />
                      </Grid>

                      <Grid item md={6} xs={12} className="paddingleft-6px">
                        <TextField
                          label="Seu e-mail"
                          name="email"
                          value={email}
                          required={false}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={(_) => handleValidateEmail(email, setEmail)}
                        />
                      </Grid>

                      <Grid item xs={12} marginTop={3}>
                        <Typography
                          component="h6"
                          variant="subtitle2"
                          className="textalign-center fontweight-600"
                        >
                          * Prezado cliente, os campos de nome e e-mail não são
                          obrigatórios para o preenchimento do presente
                          formulário. Caso deseje preenchê-los, informamos que
                          os seus dados pessoais serão tratados em conformidade
                          com a Lei Geral de Proteção de Dados e apenas para a
                          finalidade de realização desta pesquisa, bem como para
                          que possamos contatá-lo nos casos de devolutivas e/ou
                          solução de problemas reportados por você. Para mais
                          informações, consulte a nossa Política de Privacidade
                          <Typography
                            component="a"
                            variant="subtitle2"
                            className="fontweight-600 color-primary"
                            href="https://www.bridgestone.com.br/atendimento-ao-cliente/politica-de-privacidade/"
                            target="_blank"
                          >
                            {" "}
                            clicando aqui.{" "}
                          </Typography>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} marginTop={4}>
                        <Typography
                          component="h6"
                          variant="body1"
                          className="fontweight-600"
                        >
                          Como nos conheceu?
                        </Typography>

                        <FormControl>
                          <RadioGroup
                            row
                            name="howDidYouMeetUs"
                            value={comoNosConheceu}
                            onChange={(e) => setComoNosConheceu(e.target.value)}
                          >
                            {comoNosConheceyArray.map((option, index) => {
                              return (
                                <FormControlLabel
                                  value={option}
                                  key={index}
                                  control={<Radio size="small" />}
                                  label={option}
                                  sx={{ marginRight: 8 }}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>

                        {comoNosConheceu.toUpperCase() == "OUTRO" && (
                          <Grid container>
                            <Grid item md={6} xs={12}>
                              <TextField
                                label="Especifique"
                                name="howDidYouMeetUsMessage"
                                value={comoNosConheceuEspecifique}
                                required={true}
                                onChange={(e) =>
                                  setComoNosConheceuEspecifique(e.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item xs={12} marginTop={4}>
                        <Typography
                          component="h6"
                          variant="body1"
                          className="fontweight-600"
                        >
                          Como o seu atendimento foi feito?
                        </Typography>

                        <FormControl>
                          <RadioGroup
                            row
                            name="inPerson"
                            value={atendimentoPresencial}
                            onChange={(e) =>
                              setAtendimentoPresencial(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio size="small" />}
                              label="Presencial"
                              sx={{ marginRight: 8 }}
                            />

                            <FormControlLabel
                              value="N"
                              control={<Radio size="small" />}
                              label="Telefônico"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} marginTop={4}>
                        <Typography
                          component="h6"
                          variant="body1"
                          className="fontweight-600"
                        >
                          Qual o nome da pessoa que te atendeu? (Opcional)
                        </Typography>

                        <Grid container>
                          <Grid item md={6} xs={12}>
                            <TextField
                              label="Nome do funcionário"
                              name="employee"
                              value={nomeFuncionario}
                              required={false}
                              onChange={(e) =>
                                setNomeFuncionario(e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} marginTop={4}>
                        <Typography
                          component="h6"
                          variant="body1"
                          className="fontweight-600"
                        >
                          1. Numa escala de 0 a 10, qual a probabilidade de você
                          recomendar a nossa revenda a um amigo ou colega?
                        </Typography>

                        <Grid
                          container
                          className="grid-container-nps-notes"
                          marginTop={1}
                        >
                          {Array.apply(null, Array(11)).map((_, index) => {
                            const className =
                              notaNps == index ? "background-primary" : "";

                            return (
                              <Grid
                                item
                                lg={1}
                                md={2}
                                sm={4}
                                xs={6}
                                key={index}
                                padding={1}
                              >
                                <Button
                                  label={index}
                                  className={`${className} padding-none`}
                                  onClick={(_) =>
                                    setNotaNps(
                                      notaNps == index ? undefined : index
                                    )
                                  }
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} marginTop={4}>
                        <Typography
                          component="h6"
                          variant="body1"
                          className="fontweight-600"
                        >
                          2. Como você avalia a eficiência e rapidez do
                          vendedor/atendente para sanar a sua necessidade?
                        </Typography>

                        <Typography component="p" variant="body2">
                          Avalie de 1 a 5 estrelas, sendo 1 muito insatisfeito e
                          5 muito satisfeito
                        </Typography>

                        <Rating
                          name="service"
                          size="large"
                          value={pesquisaServico}
                          onChange={(e, newValue) =>
                            setPesquisaServico(newValue)
                          }
                          sx={{ marginTop: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12} marginTop={4}>
                        <Typography
                          component="h6"
                          variant="body1"
                          className="fontweight-600"
                        >
                          3. Conseguimos deixar claro todas as informações que
                          você precisava?
                        </Typography>

                        <Typography component="p" variant="body2">
                          Avalie de 1 a 5 estrelas, sendo 1 muito insatisfeito e
                          5 muito satisfeito
                        </Typography>

                        <Rating
                          name="information"
                          size="large"
                          value={pesquisaInformacao}
                          onChange={(e, newValue) =>
                            setPesquisaInformacao(newValue)
                          }
                          sx={{ marginTop: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12} marginTop={4}>
                        <Typography
                          component="h6"
                          variant="body1"
                          className="fontweight-600"
                        >
                          4. O vendedor/atendente te instruiu de forma clara
                          sobre as formas de pagamento, sem deixar nenhuma
                          dúvida?
                        </Typography>

                        <Typography component="p" variant="body2">
                          Avalie de 1 a 5 estrelas, sendo 1 muito insatisfeito e
                          5 muito satisfeito
                        </Typography>

                        <Rating
                          name="payment"
                          size="large"
                          value={pesquisaPagamento}
                          onChange={(e, newValue) =>
                            setPesquisaPagamento(newValue)
                          }
                          sx={{ marginTop: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12} marginTop={4}>
                        <Typography
                          component="h6"
                          variant="body1"
                          className="fontweight-600"
                        >
                          5. Você considera que nosso produto e/ou serviço
                          atendeu plenamente as suas expectativas?
                        </Typography>

                        <Typography component="p" variant="body2">
                          Avalie de 1 a 5 estrelas, sendo 1 muito insatisfeito e
                          5 muito satisfeito
                        </Typography>

                        <Rating
                          name="general"
                          size="large"
                          value={pesquisaGeral}
                          onChange={(e, newValue) => setPesquisaGeral(newValue)}
                          sx={{ marginTop: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12} marginTop={4}>
                        <Typography
                          component="h6"
                          variant="body1"
                          className="fontweight-600"
                        >
                          6. Gostaria de deixar algum comentário, elogio,
                          sugestão ou crítica? Sua opinião é muito importante
                          para nós!
                        </Typography>

                        <TextField
                          name="message"
                          value={comentario}
                          onChange={(e) => setComentario(e.target.value)}
                          placeholder="Deixe o seu comentário"
                          multiline={true}
                          minRows={3}
                          maxRows={3}
                          required={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} className="container-form-footer">
                    <Button
                      label="Enviar"
                      size="medium"
                      type="submit"
                      className="background-red"
                      fullWidth={false}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {surveyCompleted && (
              <Grid
                item
                xs={12}
                className="container-surveycompleted textalign-center"
              >
                <TaskAlt className="image-completed" />

                <Typography
                  variant="h5"
                  component="h1"
                  className="text-title fontweight-bold"
                >
                  {surveyCompletedMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} className="container-footer">
          <Typography
            component="span"
            variant="subtitle2"
            className="color-white"
          >
            ©️ {currentFullYear} Bridgestone Brasil
          </Typography>
        </Grid>
      </Grid>

      <DialogSacPersonalConfirmSubmit
        isOpen={confirmationDialogOpened}
        setClosedDialog={(_) => setConfirmationDialogOpened(false)}
        onConfirmation={handleSubmitConfirmation}
      />
    </>
  );
};

export default RealizedPersonal;
